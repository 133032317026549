import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_935has1GtVBnMeta } from "/opt/build/repo/pages/service/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93dyF2fzTAr5Meta } from "/opt/build/repo/pages/solutions/[...slug].vue?macro=true";
import { default as indexSicrNAjcMxMeta } from "/opt/build/repo/pages/solutions/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "service-slug",
    path: "/service/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/service/[...slug].vue")
  },
  {
    name: "solutions-slug",
    path: "/solutions/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/solutions/[...slug].vue")
  },
  {
    name: "solutions",
    path: "/solutions",
    component: () => import("/opt/build/repo/pages/solutions/index.vue")
  }
]