<template>
  <div>
    <NuxtLoadingIndicator :throttle="0" />

    <Header />
    <NuxtPage />
    <Footer />
    <Toaster richColors position="top-right" />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useFlowbite } from '~/composables/useFlowbite'

// initialize components based on data attribute selectors
onMounted(() => {
  useFlowbite(() => {
    initFlowbite()
  })
})

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [
    {
      rel: 'icon',
      type: 'image/ico',
      href: '/favicon.ico',
    },
  ],
})
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0.7;
}
</style>
