<script setup lang="ts">
const props = defineProps<{
  items: Array<{
    title: string
    subTitle?: string
    image?: string
    desc?: string
  }>
}>()
</script>
<template>
  <div id="custom-carousel" class="relative w-full" data-carousel="slide">
    <!-- Carousel wrapper -->
    <div class="relative h-96 py-72 overflow-hidden">
      <!-- Slide 1 -->
      <template v-for="(item, i) in props.items" :key="item.title + i">
        <div
          class="duration-700 ease-in-out absolute inset-0 transition-transform transform z-10 z-20"
          data-carousel-item="1"
        >
          <NuxtImg
            :src="item.image"
            class="absolute block w-full h-full object-cover"
            :alt="item.title"
          />
          <div
            class="absolute inset-0 bg-black bg-opacity-50 flex items-center lg:items-start"
          >
            <div
              class="lg:text-left container mx-auto text-white px-10 text-center lg:px-32 mt-0 lg:mt-24"
            >
              <div class="p-2 mb-4">
                <p
                  class="text-lg md:text-xl uppercase tracking-wide text-gray-200"
                  v-html="item.subTitle"
                ></p>
              </div>
              <h1
                class="text-3xl md:text-7xl font-bold"
                v-html="item.title"
              ></h1>
              <p v-html="item.desc"></p>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- Slider indicators -->
    <div
      class="absolute bottom-5 left-1/2 transform -translate-x-1/2 z-30 flex space-x-3"
    >
      <button
        v-for="(item, i) in props.items"
        :key="i"
        type="button"
        class="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800"
        aria-current="false"
        aria-label="Slide 1"
        :data-carousel-slide-to="i"
      ></button>
    </div>
    <!-- Slider controls -->
    <button
      type="button"
      class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 focus:outline-none"
      data-carousel-prev=""
    >
      <span
        class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 hover:bg-white/50"
      >
        <svg
          class="w-6 h-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
      </span>
    </button>
    <button
      type="button"
      class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 focus:outline-none"
      data-carousel-next=""
    >
      <span
        class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 hover:bg-white/50"
      >
        <svg
          class="w-6 h-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          ></path>
        </svg>
      </span>
    </button>
  </div>
</template>
