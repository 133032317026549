<script setup lang="ts">
import { useSlots } from 'vue';

const slots = useSlots(); // Access slots dynamically

defineProps({
  lists: {
    type: Array as PropType<
      Array<{
        title: string;
        icon?: string;
      }>
    >,
  },
});
</script>

<template>
  <section
    class="mt-32 mb-20 lg:mb-44 flex md:flex-col-reverse lg:flex-row flex-col-reverse items-center gap-20 max-w-6xl mx-auto px-5 md:px-10"
  >
    <!-- Image side  -->
    <div class="relative w-full lg:w-1/2">
      <div class="">
        <img
          src="/images/about-1.jpg"
          class="w-full md:w-[400px] h-[504px] object-cover"
          alt="about-1"
        />
      </div>
      <div class="md:absolute -bottom-20 right-0 mt-5 md:mt-0">
        <img
          src="/images/about-2.jpg"
          class="w-full md:w-[384px] h-[265px] object-cover"
          alt="about-2"
        />
      </div>
    </div>
    <!-- Content Side -->
    <div class="space-y-7 lg:w-1/2">
      <!-- Title -->
      <div>
        <p class="text-[#737373] relative section-title">
          <MDCSlot name="subtitle" unwrap="p" />
        </p>
        <h1 class="title mt-2 text-3xl md:text-4xl lg:text-5xl font-syne font-semibold">
          <MDCSlot name="title" unwrap="p" />
        </h1>
      </div>
      <p class="text-[#737373]">
        <MDCSlot name="desc" unwrap="p" />
      </p>
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex gap-5 items-center">
          <svg
            class="fill-[#a989fe] hover:fill-black transition duration-500 stroke-2 w-20 h-20"
            height="80px"
            width="80px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512.001 512.001"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M508.459,446.275l-38.477-33.347c-4.283-3.711-10.763-3.247-14.474,1.033c-3.711,4.283-3.248,10.762,1.034,14.474
			l17.691,15.332h-45.857v-79.076l62.638,9.194c5.604,0.829,10.819-3.055,11.642-8.661c0.823-5.606-3.056-10.819-8.662-11.642
			l-65.618-9.632V84.649c0-5.666-4.594-10.26-10.26-10.26h-76.954c-5.667,0-10.26,4.595-10.26,10.26v271.752l-25.651,10.388v-165.17
			c0-5.666-4.594-10.261-10.261-10.261h-76.954c-5.667,0-10.261,4.595-10.261,10.261v173.844l-25.651-6.098v-43.594
			c0-5.666-4.594-10.26-10.26-10.26H94.91c-5.667,0-10.26,4.595-10.26,10.26v47.98l-25.651,9.241V50.079L74.33,67.77
			c2.03,2.341,4.885,3.541,7.758,3.541c2.381,0,4.774-0.825,6.716-2.507c4.282-3.711,4.745-10.192,1.033-14.472L56.49,15.855
			c-0.043-0.05-0.084-0.089-0.127-0.135c-1.083-1.206-2.453-2.166-4.041-2.758c-4.011-1.498-8.534-0.343-11.34,2.892L7.636,54.331
			c-3.71,4.283-3.247,10.762,1.035,14.473c4.283,3.711,10.762,3.248,14.474-1.033L38.477,50.08v393.688H10.26
			c-5.667,0-10.26,4.595-10.26,10.261c0,5.666,4.594,10.26,10.26,10.26h28.216v25.138c0,5.666,4.594,10.26,10.261,10.26
			s10.26-4.595,10.26-10.26v-25.138h415.236l-17.691,15.332c-4.282,3.711-4.745,10.192-1.033,14.472
			c2.029,2.341,4.885,3.541,7.758,3.541c2.382,0,4.774-0.825,6.716-2.507l38.477-33.347c0.072-0.062,0.13-0.12,0.195-0.18
			c1.178-1.074,2.116-2.427,2.699-3.987C512.851,453.601,511.696,449.081,508.459,446.275z M84.649,443.769H58.998v-38.963
			l25.651-9.241V443.769z M161.603,443.768H105.17v-55.597l26.766-9.642l29.667,7.052V443.768z M161.603,364.489l-27.896-6.63
			c-1.938-0.462-3.974-0.347-5.851,0.328l-22.687,8.172v-30.327h0.001h56.433V364.489z M207.776,443.768h-25.651v-53.309
			l25.651,6.098V443.768z M228.297,211.88h56.433v103.631h-56.433V211.88z M228.297,336.032h56.433v39.066l-29.031,11.757
			l-27.402-6.513V336.032z M284.73,443.768h-56.434v-42.334l25.844,6.143c2.074,0.494,4.249,0.328,6.224-0.472l24.366-9.867V443.768
			z M330.902,443.768H305.25v-54.839l25.651-10.388V443.768z M407.856,443.768h-56.433v-73.537l30.51-12.356l25.923,3.805V443.768z
			 M407.856,340.94l-25.701-3.773c-1.806-0.269-3.65-0.045-5.342,0.641l-25.391,10.283v-90.039h56.433V340.94z M407.856,237.531
			h-56.433V94.91h56.433V237.531z"
                ></path>
              </g>
            </g>
          </svg>
          <h4 class="text-xl font-syne font-semibold">
            <MDCSlot name="focus_title1" unwrap="p" />
          </h4>
        </div>
        <div class="flex gap-5 items-center">
          <svg
            class="fill-[#a989fe] hover:fill-black transition duration-500 stroke-2 w-20 h-20"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 496 496"
            xml:space="preserve"
          >
            <g>
              <g>
                <g>
                  <rect x="448" y="192" width="16" height="80"></rect>
                  <rect x="448" y="384" width="16" height="80"></rect>
                  <rect x="32" y="192" width="16" height="80"></rect>
                  <rect x="32" y="384" width="16" height="80"></rect>
                  <path
                    d="M416,128c0-22.056-17.944-40-40-40h-62.352c-5.32,0-9.648-4.328-9.648-9.648V40c0-19.848-16.152-36-36-36
				c-19.848,0-36,16.152-36,36v29.216c0,18.24-14.152,33.072-32.032,34.504C199.816,90.616,189.136,80,176,80h-72
				c-13.232,0-24,10.768-24,24v56H0v144h8c13.232,0,24,10.768,24,24s-10.768,24-24,24H0v144h496V352h-8c-13.232,0-24-10.768-24-24
				s10.768-24,24-24h8V160h-80V128z M200,119.928c26.712-1.448,48-23.64,48-50.712V40c0-11.024,8.968-20,20-20
				c11.032,0,20,8.976,20,20v38.352C288,92.496,299.504,104,313.648,104H376c13.232,0,24,10.768,24,24v104c0,13.232-10.768,24-24,24
				H254.424c-6.352,0-12.24-3.152-15.768-8.44c-6.496-9.744-17.368-15.56-29.08-15.56H200V119.928z M368,416v32h-16v-32h-16v32h-16
				v-32h-16v32h-24.208c5.08-6.704,8.208-14.96,8.208-24c0-9.04-3.128-17.296-8.208-24H400v48h-16v-32H368z M112,384v-16h272v16H112
				z M272,424c0,13.232-10.768,24-24,24s-24-10.768-24-24s10.768-24,24-24S272,410.768,272,424z M216.208,448H192v-32h-16v32h-16
				v-32h-16v32h-16v-32h-16v32H96v-48h120.208c-5.08,6.704-8.208,14.96-8.208,24C208,433.04,211.128,441.296,216.208,448z
				 M216.208,352H192v-32h-16v32h-16v-32h-16v32h-16v-32h-16v32H96v-48h120.208c-5.08,6.704-8.208,14.96-8.208,24
				C208,337.04,211.128,345.296,216.208,352z M224,328c0-13.232,10.768-24,24-24s24,10.768,24,24s-10.768,24-24,24
				S224,341.232,224,328z M384,352v-32h-16v32h-16v-32h-16v32h-16v-32h-16v32h-24.208c5.08-6.704,8.208-14.96,8.208-24
				c0-9.04-3.128-17.296-8.208-24H400v48H384z M200,248h9.576c6.352,0,12.24,3.16,15.768,8.44c6.496,9.736,17.368,15.56,29.08,15.56
				H376c2.736,0,5.416-0.28,8-0.808V288H112v-16h64C189.232,272,200,261.232,200,248z M96,104c0-4.408,3.584-8,8-8h72
				c4.416,0,8,3.592,8,8v144c0,4.408-3.584,8-8,8h-72c-4.416,0-8-3.592-8-8V104z M480,176v112.808c-18.232,3.72-32,19.872-32,39.192
				s13.768,35.48,32,39.192V480H16V367.192C34.232,363.48,48,347.32,48,328s-13.768-35.48-32-39.192V176h64v72
				c0,10.416,6.712,19.216,16,22.528V288H80v80h16v16H80v80h336v-80h-16v-16h16v-80h-16v-24h-0.248
				c9.8-7.296,16.248-18.872,16.248-32v-56H480z"
                  ></path>
                  <path
                    d="M136,240c13.232,0,24-10.768,24-24s-10.768-24-24-24s-24,10.768-24,24S122.768,240,136,240z M136,208
				c4.416,0,8,3.592,8,8s-3.584,8-8,8s-8-3.592-8-8S131.584,208,136,208z"
                  ></path>
                  <rect x="368" width="16" height="16"></rect>
                  <rect x="368" y="32" width="16" height="16"></rect>
                  <rect x="384" y="16" width="16" height="16"></rect>
                  <rect x="352" y="16" width="16" height="16"></rect>
                  <rect x="144" y="16" width="16" height="16"></rect>
                  <rect x="144" y="48" width="16" height="16"></rect>
                  <rect x="160" y="32" width="16" height="16"></rect>
                  <rect x="128" y="32" width="16" height="16"></rect>
                  <rect x="464" y="80" width="16" height="16"></rect>
                  <rect x="464" y="112" width="16" height="16"></rect>
                  <rect x="480" y="96" width="16" height="16"></rect>
                  <rect x="448" y="96" width="16" height="16"></rect>
                  <rect x="48" y="48" width="16" height="16"></rect>
                  <rect x="48" y="80" width="16" height="16"></rect>
                  <rect x="64" y="64" width="16" height="16"></rect>
                  <rect x="32" y="64" width="16" height="16"></rect>
                </g>
              </g>
            </g>
          </svg>
          <h4 class="text-xl font-syne font-semibold">
            <MDCSlot name="focus_title2" unwrap="p" />
          </h4>
        </div>
      </div>
      <!-- Features -->
      <ul class="space-y-2">
        <li
          class="flex items-center gap-3 font-medium"
          v-for="(list, i) in lists"
          :key="i"
        >
          <Icon :name="list.icon || 'line-md:arrow-right-circle'" />
          {{ list.title }}
        </li>
      </ul>
      <!-- Discover More Button -->
      <button
        v-if="$slots.cta_text"
        class="py-4 px-10 w-full md:w-fit text-white font-medium bg-gradient-to-tr from-[#7a4df4] to-[#a989fe] discover-btn"
      >
        <MDCSlot name="cta_text" unwrap="p" />
      </button>
    </div>
  </section>
</template>
