<script setup lang="ts">
import type { PropType } from 'vue'

defineProps({
  solutions: {
    type: Array as PropType<
      Array<{
        title: string
        desc: string
        image: string
        type: string
      }>
    >,
  },
})
const allSolutions = await queryContent('solution')
  .where({ _stem: { $not: 'solutions/index' } })
  .sort({ order: 1 })
  .find()
</script>
<template>
  <section class="w-full pb-8">
    <!-- Title -->
    <div class="text-center pt-20">
      <p class="relative section-title">
        <MDCSlot name="subTitle" unwrap="p" />
      </p>
      <h1 class="mt-2 text-3xl md:text-4xl lg:text-5xl font-syne font-semibold">
        <MDCSlot name="title" unwrap="p" />
      </h1>
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 w-full px-5 md:px-10 max-w-[1400px] mx-auto gap-7 my-20"
    >
      <div
        class="relative rounded-[24px] pb-3 overflow-hidden shadow border"
        v-for="(solution, index) in allSolutions"
        :key="index"
      >
        <img
          :src="solution?.image || '/images/project-4.jpg'"
          class="w-full md:w-auto"
          alt=""
        />
        <div class="w-full px-4 relative">
          <span
            class="absolute -top-4 py-0.5 pb-1 px-5 text-sm rounded-full md:w-fit text-white bg-gradient-to-tr from-[#7a4df4] to-[#a989fe]"
          >
            {{ solution.type }}
          </span>
          <div class="bg-white pt-5 pb-3 px-1">
            <NuxtLink :to="solution._path">
              <h4
                class="text-[18px] font-syne font-semibold"
                v-html="solution.title"
              ></h4>
            </NuxtLink>
            <p v-html="solution.desc"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
