<script setup lang="ts">
import type { PropType } from 'vue'

const allServices = await queryContent('service')
  .where({ _dir: { $not: '' } })
  .sort({ order: 1 })
  .find()
</script>
<template>
  <section class="py-24 bg-[#f4f2f9]">
    <div class="max-w-7xl mx-auto px-6">
      <div class="flex flex-col lg:flex-row justify-between gap-10 lg:gap-0">
        <div class="w-3/5">
          <h3 class="text-lg font-medium text-gray-500">
            <MDCSlot name="subTitle" unwrap="p" />
          </h3>
          <h2 class="text-4xl font-bold text-gray-800 mt-2">
            <MDCSlot name="title" unwrap="p" />
          </h2>
        </div>
        <div class="w-2/5">
          <hr class="border-[3px] border-[#9333ea]" />
          <p class="mt-7 text-gray-500 text-lg">
            <MDCSlot name="desc" unwrap="p" />
          </p>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-6 gap-6 mt-10">
        <div
          class="group bg-white shadow-md rounded-lg p-6 text-center relative overflow-hidden transition-all duration-500"
          v-for="(service, index) in allServices"
          :key="index"
        >
          <div
            class="absolute inset-0 bg-gradient-to-t from-[#9333ea] to-[#9333ea] transform scale-y-0 origin-bottom transition-transform duration-500 group-hover:scale-y-100"
          ></div>
          <h3
            class="font-semibold text-lg text-gray-800 group-hover:text-white relative z-10"
          >
            {{ service.title }}
          </h3>
          <div
            class="text-purple-600 text-4xl mb-4 mt-4 group-hover:text-white relative z-10"
          >
            <Icon :name="service.icon" />
          </div>
          <p class="mt-2 text-gray-500 group-hover:text-white relative z-10">
            <NuxtLink :to="service._path">
              {{ service.buttonText || 'Find More' }}
            </NuxtLink>
          </p>
        </div>
      </div>

      <!-- Call to Action -->
      <div
        class="mt-12 text-center flex flex-col lg:flex-row justify-center items-center gap-5 lg:gap-10 border-2 border-purple-600 p-2 w-full lg:w-2/3 mx-auto"
      >
        <p class="text-gray-600 text-lg">
          Need any custom solution
          <NuxtLink to="contact" class="text-purple-600 font-semibold">
            Send a request now
          </NuxtLink>
        </p>
      </div>
    </div>
  </section>
</template>
