<script setup lang="ts">
import { onMounted } from 'vue';
import type { PropType } from 'vue';

import { useFlowbite } from '~/composables/useFlowbite';
import type { Banner, ServiceCardProp } from '~/types/home-page';
// onMounted(() => {
//   useFlowbite(() => {

//     const carousel = new Carousel(carouselElement, items, options, instanceOptions);
//   })
// })

const { data: projects } = await useAsyncData('banners', () =>
  queryContent('/index').sort({ release: -1 }).find()
);

const props = defineProps({
  banners: {
    type: Array as PropType<Banner[]>,
    required: true,
  },
  services: {
    type: Array as PropType<ServiceCardProp[]>,
    required: true,
  },
});
</script>

<template>
  <main>
    <Banner :items="props.banners" />

    <!-- <section class="container mx-auto mb-8 px-5 lg:px-32 mt-20">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <ServiceCard
          v-for="(service, index) in props.services"
          :key="index"
          :title="service.title"
          :desc="service.desc"
          :image="service.image"
          :icon="service.icon"
          :link="service.link"
        />
      </div>
    </section> -->

    <MDCSlot name="default" />
  </main>
</template>
