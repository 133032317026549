<script setup lang="ts">
import type { MenuItem } from '~/types/home-page'

const { data: siteData } = await useAsyncData('stack', () =>
  queryContent('/sitedata').findOne(),
)

const showMobileMenu: Ref<boolean> = ref(false)
const menuItem: Ref<MenuItem[]> = ref([
  {
    name: 'Home',
    to: '/',
    icon: 'lucide:home',
  },
  {
    name: 'About',
    to: '/about',
    icon: 'lucide:user',
  },
  {
    name: 'Services',
    to: '/services',
    icon: 'lucide:briefcase',
  },
  {
    name: 'Solutions',
    to: '/solutions',
    icon: 'lucide:briefcase',
  },
  // {
  //   name: 'Writing',
  //   to: '/writing',
  //   icon: 'lucide:library',
  // },
  {
    name: 'Contact',
    to: '/contact',
    icon: 'lucide:mail',
  },
])
</script>
<template>
  <section>
    <TopHeader />
    <nav class="bg-white shadow-md flex items-center relative z-50">
      <!-- Logo Section -->
      <div class="border-r-4 border-purple-500 py-3 bg-[#1d1729]">
        <div class="flex items-center space-x-4 ml-16 pr-16">
          <NuxtLink to="/" class="text-4xl font-bold text-purple-500">
            <NuxtImg class="w-64" src="/ekopii-limited-logo.png" />
          </NuxtLink>
        </div>
      </div>

      <!-- Large Screen Navbar -->
      <div
        class="container mx-auto px-4 flex justify-between items-center pl-8 lg:pl-16 w-full"
      >
        <Navbar :menuItems="menuItem" />
        <!-- Large Screen Right Section -->
        <div class="hidden lg:flex items-center space-x-4">
          <div class="flex gap-6 items-center pr-10">
            <div>
              <i class="fas fa-comments text-purple-500 text-4xl"></i>
            </div>
            <div class="">
              <span class="text-base text-gray-600">Have any question?</span>
              <br />
              <p class="text-base font-bold text-gray-800">
                <span class="text-gray-500">Call</span> {{ siteData?.phone }}
              </p>
            </div>
          </div>
        </div>

        <!-- Small Screen Hamburger Icon -->
        <button
          class="lg:hidden text-3xl text-gray-700 focus:outline-none flex justify-end w-full"
          id="menu-toggle"
          @click="showMobileMenu = !showMobileMenu"
        >
          <Icon name="flowbite:bars-outline" class="w-14 h-14" />
        </button>
      </div>
    </nav>
    <div
      id="mobile-menu"
      class="fixed inset-0 bg-gray-900 bg-opacity-90 text-white flex flex-col items-center justify-center space-y-6 transform transition-transform duration-300 z-50"
      :class="!showMobileMenu ? 'translate-x-full' : ''"
    >
      <button
        @click="showMobileMenu = !showMobileMenu"
        class="absolute top-4 right-4 text-4xl"
      >
        <Icon name="material-symbols:cancel-outline" />
      </button>
      <div v-for="(item, index) in menuItem" :key="index">
        <NuxtLink :to="item.to" class="text-2xl hover:text-purple-500">
          {{ item.name }}
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
